import React from "react";
import "./Portfolio.css";
import IMG1 from "../../assets/portfolio1.jpg";
import IMG2 from "../../assets/portfolio2.jpg";
import IMG3 from "../../assets/portfolio3.jpg";
import IMG4 from "../../assets/portfolio4.jpg";
import IMG5 from "../../assets/portfolio5.png";
import IMG6 from "../../assets/portfolio6.jpg";

const data = [
    {
        id: 1,
        image: IMG1,
        title: "Crypto Currency Dashboard & Financial Visualization",
        github: "https://github.com",
        demo: "https://dribbble.com/shots/20579260-Eclipse-Figma-dashboard-UI-kit-for-data-design-web-apps",
    },
    {
        id: 2,
        image: IMG2,
        title: "Charts templates & infographics in Figma",
        github: "https://github.com",
        demo: "https://dribbble.com/shots/20579260-Eclipse-Figma-dashboard-UI-kit-for-data-design-web-apps",
    },
    {
        id: 3,
        image: IMG3,
        title: "Figma dashboard UI kit for data design web apps",
        github: "https://github.com",
        demo: "https://dribbble.com/shots/20579260-Eclipse-Figma-dashboard-UI-kit-for-data-design-web-apps",
    },
    {
        id: 4,
        image: IMG4,
        title: "Maintaining tasks and tracking progress",
        github: "https://github.com",
        demo: "https://dribbble.com/shots/20579260-Eclipse-Figma-dashboard-UI-kit-for-data-design-web-apps",
    },
    {
        id: 5,
        image: IMG5,
        title: "Charts templates & infographics in Figma",
        github: "https://github.com",
        demo: "https://dribbble.com/shots/20579260-Eclipse-Figma-dashboard-UI-kit-for-data-design-web-apps",
    },
    {
        id: 6,
        image: IMG6,
        title: "Charts templates & infographics in Figma",
        github: "https://github.com",
        demo: "https://dribbble.com/shots/20579260-Eclipse-Figma-dashboard-UI-kit-for-data-design-web-apps",
    },
];

const Portfolio = () => {
    return (
        <section id="portfolio">
            <h5>My Recent Work</h5>
            <h2>Portfolio</h2>
            <div className="container portfolio-container">
                {data.map((portfolio) => {
                    return (
                        <article key={portfolio.id} className="portfolio-item">
                            <div className="portfolio-item-image">
                                <img src={portfolio.image} alt={portfolio.title} />
                            </div>

                            <h3>{portfolio.title}</h3>

                            <div className="portfolio-item-cta">
                                {" "}
                                <a
                                    href={portfolio.github}
                                    className="btn"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Github
                                </a>
                                <a
                                    href={portfolio.demo}
                                    className="btn btn-primary"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Live Demo
                                </a>
                            </div>
                        </article>
                    );
                })}
            </div>
        </section>
    );
};

export default Portfolio;
